import { useEffect, useState } from "react"
import { DataTableQuality } from "./DataTable"
import { useDataGouv } from "./providers/DataGouvProvider"
import { PermissionCategory, useDataQuality } from "./hooks/useDataQuality"
import { useActiveTabs, useAuth } from "../../providers"
import { SelectOptions, SelectOptionsDataGouv } from "../DocumentTranslator/SelectedOptions"
import { Ingestion } from "./Ingestion"
import { Tile } from "powerbi-client"
import { DataGouvHome } from "./DataGouvHome"
import { SVGIcon } from "../../assets"
import { Referentiel } from "./Referentiel"
import { DataLineage } from "./DataLineage"
import { AuthDenied } from "./AuthDenied"

interface BUProps {
    title: string
    tabId: number
}

const navOpt = [
    {
        id: 'Quality',
        title: "Quality",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Ingestion',
        title: "Ingestion",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Referentiel',
        title: "Referentiel",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Lineage',
        title: "Data Lineage",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    }
]

const listUseCase = ["Traffic","Finance","Retail","Communication","Carpark","Ops","HR","External","Predict"]
  

export const BUMainApp: React.FC<BUProps> = ({title, tabId}) => {
    const {buSelected, setBuSelected, dateSelected, setUseCase ,setDateSelected, buOptions, dateOptions, getQualityDetailForBu, app, setApp, useCase, userPermissions, loaderIngestionData, loaderReferentielData,loaderLineageData, authorisedApps, setAuthorisedApps, activeTable} = useDataGouv()
    const {token} = useAuth()
    const [dataForTable, setDataForTable] = useState({
        mapping_coverage: [],
        data_integrity: [],
        file_integrity: [],
        file_not_received: [],
    });
    
    const [filterStatus, setFilterStatus] = useState<string | null>(null)
    const [loaderTable, setLoaderTable] = useState<boolean>(false)
    const [selectedUniqueFileType, setSelectedUniqueFileType] = useState<string | null>(null);
    const {openTab, tabState} = useActiveTabs()
    const [authorised, setAuthorised] = useState<boolean>(false)
    // const [authorisedApps, setAuthorisedApps] = useState<string[]>([])
    const [filteredNavOpt, setFilteredNavOpt] = useState(navOpt);
    const [validBus, setValidBUs] = useState<string[] | null>(null)

    const [hasLoadedAuthorisedApps, setHasLoadedAuthorisedApps] = useState(false);

    useEffect(() => {
        if (authorisedApps && authorisedApps.length > 0 && !hasLoadedAuthorisedApps) {
          setHasLoadedAuthorisedApps(true);
        }
      }, [authorisedApps]);

    const getValidBus = (use_case: string) => {
        if (!userPermissions) return;
        const sanitizedUseCase = use_case.replace(/\s+/g, "").toUpperCase();
        const valid = Object.keys(userPermissions).filter((bu) => {
            const permissions = userPermissions[bu];
            return Object.values(permissions).some((useCases) =>
                useCases.some((uc: string) => uc.replace(/\s+/g, "").toUpperCase() === sanitizedUseCase)
            );
        });
        setValidBUs(valid);
    };

    // useEffect(()=>{
    //     console.log(validBus)
    //     console.log(authorisedApps)
    // },[validBus, authorisedApps])

    const checkUserPermissions = (use_case: string) => {
        if (!userPermissions || !buSelected) return;
        const sanitizedUseCase = use_case.replace(/\s+/g, "").toUpperCase();
    
        const rightsForBu = userPermissions[buSelected] ?? []
    
        const hasUseCase = Object.values(rightsForBu).some((useCases) =>
            useCases.some((uc: string) => uc.replace(/\s+/g, "").toUpperCase() === sanitizedUseCase)
        );

        setAuthorised(hasUseCase);
        getValidBus(sanitizedUseCase);
    
        const appsAuthorised = Object.keys(rightsForBu).filter((category) => {
            const key = category as keyof PermissionCategory;
            return rightsForBu[key].some((uc) => uc.replace(/\s+/g, "").toUpperCase() === sanitizedUseCase);
        });
        setAuthorisedApps(appsAuthorised);
    };
    

    useEffect(() => {
        if (tabId === tabState.activeTabId) { 
          setUseCase(title);
        }
    }, [title, tabState.activeTabId, tabId]);

    useEffect(()=>{
        if (tabId === tabState.activeTabId && userPermissions && buSelected) { 
            checkUserPermissions(title)
        }
    },[title, tabId, tabState.activeTabId, buSelected])

    useEffect(() => {
        if(!authorisedApps) return
        const updatedNavOpt = navOpt.filter((elem) => authorisedApps.includes(elem.id));
        setFilteredNavOpt(updatedNavOpt!);
    }, [authorisedApps, tabState.activeTabId]);

    // useEffect(()=>{
    //     if(!authorisedApps) return
    //     if(authorisedApps.includes(app)) return
    //     setApp(authorisedApps![0])
    // },[tabState.activeTabId, authorisedApps])
    

    const defaultTab = {
        id: 50,
        appId: "GenAI/SecureChatGPT",
        title: "Overview",
        icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
        description: [],
        app: () => <DataGouvHome/>,  
    }

    const setUpTable = async() =>{
        if(!authorisedApps?.includes('Quality')) return
        setLoaderTable(true)
        const data = await getQualityDetailForBu(buSelected!,title.toLocaleUpperCase(), parseInt(dateSelected!))
        setDataForTable(data)
        setLoaderTable(false)
    }

    useEffect(() => {
        if (
          app === 'Quality' &&
          tabState.activeTabId === tabId &&
          dateSelected &&
          hasLoadedAuthorisedApps
        ) {
          setUpTable();
        }
    }, [buSelected, dateSelected, app, tabState.activeTabId, hasLoadedAuthorisedApps]);

    return(
        <div className="flex flex-col h-full items-center">
            <div className="flex flex-col bg-white p-5 items-center justify-center text-text-primary-light w-full">
            <div className="flex flex-row w-full justify-between items-center">
                <div className="overflow-x-auto">
                    <div className="flex flex-row gap-4">
                    <div className="flex flex-row h-[40px] w-fit items-center border rounded-md text-black text-sm">
                        <button
                        className="flex font-semibold items-center px-5 py-1.5"
                        onClick={() => openTab(defaultTab)}
                        >
                        Overview
                        </button>
                        <div className="flex items-center rounded-md h-full w-fit cursor-pointer">
                        {filteredNavOpt.map((e, i) => {
                            const isCurrentApp = e.id === app;
                            const bgColorClass = isCurrentApp
                            ? `bg-[#D0D5DD] bg-opacity-50 text-[#004289]`
                            : "text-[#344054]";
                            const transitionClass = isCurrentApp
                            ? "transition-all duration-300 ease-in-out"
                            : "";
                            return (
                            <div
                                key={i}
                                className={`h-full flex items-center border-l px-5 py-1.5 ${bgColorClass} ${transitionClass}`}
                                onClick={() => setApp(e.id)}
                            >
                                <h3 className="font-semibold">
                                {e.title.replaceAll(" ", "\u00A0")}
                                </h3>
                            </div>
                            );
                        })}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-center">
                    <p className="text-[#667085] font-medium">BU Selection:</p>
                    <SelectOptionsDataGouv
                    options={buOptions}
                    defaultValue="-"
                    renderOption={(method) => ({ value: method, label: method })}
                    selectedValue={buSelected!}
                    onChange={(e) => {
                        if (
                        loaderIngestionData ||
                        loaderLineageData ||
                        loaderReferentielData
                        )
                        return;
                        setBuSelected(e.target.value);
                    }}
                    />
                </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-start p-7 -mb-3 mt-1  gap-2">
                <p className="font-trendaSemiBold text-2xl w-full text-black">{app} {(buSelected === 'SSA') && (useCase?.toLocaleUpperCase() === 'FINANCE') ? 'SSA/AMA' : buSelected} - {title}</p>
            </div>
            {authorised ? 
                <div className={`p-4 w-full ${app === 'Quality' ? '' : 'p-4'}`}>
                    {app === 'Quality' && (
                        <DataTableQuality
                            selectedUniqueFileType={selectedUniqueFileType}
                            setSelectedUniqueFileType={setSelectedUniqueFileType}
                            data={dataForTable}
                            loader={loaderTable}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                        />
                    )}
                    {app === 'Ingestion' && <Ingestion />}
                    {app === 'Referentiel' && <Referentiel />}
                    {app === 'Lineage' && <DataLineage />}
                </div>
                :
                <div className="p-4 w-full">
                    <AuthDenied validBus={validBus!}/>
                </div>
            }
        </div>
    )
}