import { CloudDropzone } from "./Icons";
import React, { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileWithId } from "./FileLoaderTable";
import { v4 as uuidv4 } from 'uuid';


interface DropzoneProps {
    setFiles: React.Dispatch<React.SetStateAction<FileWithId[] | []>>;
    files: FileWithId[]
  }
  
  export const Dropzone: FC<DropzoneProps> = ({ setFiles, files }) => {
  
    const onDrop = (acceptedFiles: File[]) => { 
      const filesWithId: FileWithId[] = acceptedFiles.map(file => ({
        file,
        id: uuidv4(),
      }));  
      setFiles((prevFiles) => [...prevFiles, ...filesWithId]); 
    };
  
    const { getRootProps, getInputProps, isDragReject, fileRejections } = useDropzone({
      accept: {
        "application/pdf": [".pdf"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
        "text/plain": [".txt"],    
        "text/csv": [".csv"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
      },
      onDrop,
      multiple: true,
      maxSize: 2 * 1024 * 1024 * 1024, // 2GB
    });
  
    return (
      <div
        {...getRootProps()}
        className={`dropzone border-dashed flex flex-col items-center justify-center border-2 border-[#344054] rounded-xl px-2 py-3 text-center cursor-pointer`}
      >
        <input {...getInputProps()} />
        <CloudDropzone/>
        <p className="text-sm text-[#344054] mt-1">
          <span className="underline">Click to upload</span> or Drag and drop
        </p>
      </div>
    );
  };