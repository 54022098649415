import { createContext, useContext, useEffect, useState } from "react";
import { useDataQuality } from "../hooks/useDataQuality";
import { useAuth } from "../../../providers";
import { useDataIngestion } from "../hooks/useDataIngestion";
import { FileStatusProps } from "../components/FileStatus";
import { IngestionData } from "../hooks/useDataIngestion";
import { ReferentielData, useReferentiel } from "../hooks/useReferentiel";
import { FileStatusRefProps } from "../components/FileStatusReferentiel";
import { EdgesData, LineageKpisData, NodesData, useDataLineage } from "../hooks/useDataLineage";
import { UserPermissions } from "../hooks/useDataQuality";

interface FileData {
    file_type: string;
    is_version: number;
    num_version: number;
}

interface AssetData {
    [key: string]: FileData[];
}

interface FakeDataProps {
    fileType: string;
    status: FileStatusProps['status']
}


interface UploadFileMeta {
    fileType: string;
    version?: string;
}

interface UploadMultipleParams {
    bu: string;
    fileAsset: string;
    filesMeta: UploadFileMeta[];
    file: File;
}

interface DataGouvContextType {
    buSelected: string | null;
    setBuSelected: React.Dispatch<React.SetStateAction<string | null>>;
    dateSelected: string | null;
    setDateSelected: React.Dispatch<React.SetStateAction<string | null>>;
    setMonthYearSelected: React.Dispatch<React.SetStateAction<string | null>>;
    monthYearSelected: string | null;
    dataQuality: any;
    buOptions: string[];
    dateOptions: string[];
    getQualityDetailForBu: (asset: string, use_case: string, year: number) => Promise<any>;
    lateFilesNumber: number;
    ingestionData: IngestionData | null;
    uploadFileFromDropzone: (file_asset: string, file_type: string, file: File, version?: string) => Promise<any>;
    uploadFileFromDropzoneMultiple: (params: UploadMultipleParams) => Promise<void>;
    uploadFileFromDropzoneRef: (file_type: string, file: File, file_url: string) => Promise<any>;
    uploadFileFromDropzoneRefIngestion: (file_type: string, file: File, buSelected: string, month: string, year: string) => Promise<any>
    confirmFileAfterKpis: (file_asset: string, file_type: string, version?: string, monthYearSelected?: string, tmp_bucket_url?: string[]) => Promise<any>
    rejectKPIs: (file_asset: string, file_type: string, version?: string) => void;
    useCase: string | null;
    setUseCase: React.Dispatch<React.SetStateAction<string | null>>;
    app: string;
    activeTable: string;
    setApp: React.Dispatch<React.SetStateAction<string>>;
    setActiveTable: React.Dispatch<React.SetStateAction<string>>;
    loaderIngestionData: boolean;
    dataReferentiel: ReferentielData | null;
    loaderReferentielData: boolean;
    dataLineage: LineageKpisData | null;
    nodes: NodesData | null
    edges: EdgesData | null
    getTables: (filePath: string) => Promise<void | null>;
    getEdges: (filePath: string) => Promise<void | null>;
    loaderOverview: boolean;
    loaderLineageData: boolean;
    userPermissions: UserPermissions | null
    authorisedApps: string[] | null
    setAuthorisedApps: React.Dispatch<React.SetStateAction<string[] | null>>
    authorisedBUOptions: string[];
    availableYears: string[] | null;
    loadingDownloads: { [url: string]: boolean }
    handleDownload: (url: string) => Promise<void | null>;

}

const DataGouvContext = createContext<DataGouvContextType | undefined>(undefined);

export const DataGouvProvider = ({ children }: React.PropsWithChildren) => {
    const [buSelected, setBuSelected] = useState<string | null>(null);
    const [dateSelected, setDateSelected] = useState<string | null>(null);
    const [monthYearSelected, setMonthYearSelected] = useState<string | null>(null)
    const [buOptions, setBuOptions] = useState<string[]>([]);
    const [dateOptions, setDateOptions] = useState<string[]>([]);
    const [lateFilesNumber, setLateFilesNumber] = useState<number>(0);
    const { token } = useAuth();
    const { dataQuality, getQualityDetailForBu, loaderOverview, userPermissions, authorisedBUOptions, availableYears } = useDataQuality(token);
    const [useCase, setUseCase] = useState<string | null>(null)

    const [authorisedApps, setAuthorisedApps] = useState<string[] | null>([])

    const [app, setApp] = useState('')
    const [activeTable, setActiveTable] = useState<string>("File Not Received")

    const { uploadFile, getIngestionData, ingestionData, setIngestionData, confirmFile, loaderIngestionData, handleDownload, loadingDownloads } = useDataIngestion(token);
    const { getReferentielData, dataReferentiel, uploadFileRef, setDataReferentiel, uploadFileRefIngestion, loaderReferentielData } = useReferentiel(token)
    const { dataLineage, getLineageKpis, getTables, nodes, getEdges, edges, loaderLineageData } = useDataLineage(token)

    useEffect(() => {
        if (authorisedApps) {
            if (authorisedApps.includes(app)) return
            const order = ['Quality', 'Ingestion', 'Referentiel', 'Lineage'];
            const sortedApps = [...authorisedApps].sort(
                (a, b) => order.indexOf(a) - order.indexOf(b)
            );
            setApp(sortedApps[0]);
        }
    }, [authorisedApps]);

    useEffect(() => {
        //console.log('provider', authorisedApps, buSelected, useCase)
        if (useCase === 'Overview') return
        if (!authorisedApps?.includes('Ingestion')) return
        if (buSelected && monthYearSelected && useCase) {
            getIngestionData(useCase.toLocaleUpperCase(), buSelected, monthYearSelected!);
        }
    }, [monthYearSelected, authorisedApps]);

    useEffect(() => {
        if (useCase === 'Overview') return
        if (!authorisedApps?.includes('Referentiel')) return
        if (buSelected && useCase) {
            getReferentielData(useCase?.toLocaleUpperCase(), buSelected)
        }
    }, [authorisedApps])

    useEffect(() => {
        if (useCase === 'Overview') return
        if (!authorisedApps?.includes('Lineage')) return
        if (useCase && buSelected) {
            // console.log('provider',useCase)
            getLineageKpis(useCase, buSelected!)
        }
    }, [authorisedApps])

    const uploadFileFromDropzoneRef = async (file_type: string, file: File, file_url: string) => {
        if (dataReferentiel) {
            // console.log(dataReferentiel)
            try {
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;

                            if (fileTypeMatch) {
                                const statusKeys: (keyof FileStatusRefProps['status'])[] = ['loaded', 'checkfile', 'processed'];
                                const updatedStatus = { ...row.status };
                                statusKeys.forEach(key => {
                                    if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                                        updatedStatus[key] = 'pending';
                                    }
                                });

                                return {
                                    ...row,
                                    status: { ...updatedStatus, loaded: 'running' },
                                    error: undefined,
                                };
                            }
                            return row;
                        }),
                    };
                });

                const data = await uploadFileRef(file_type, file, buSelected!, 'FINANCE');

                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
                            // console.log(row);
                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: {
                                        loaded: data.status.loaded,
                                        checkfile: data.status.checkfile,
                                        processed: data.status.processed,
                                    },
                                    final_filename: data.final_filename,
                                    error: data.error ? data.error : undefined,
                                };
                            }
                            return row;
                        }),
                    };
                });
            } catch (error) {
                console.error('Error during file upload process:', error);
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;

                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: { ...row.status, loaded: 'error' },
                                    error: {
                                        title: 'Error',
                                        description: ''
                                    },
                                };
                            }
                            return row;
                        }),
                    };
                });
            }
        }
    };

    const uploadFileFromDropzoneRefIngestion = async (file_type: string, file: File, buSelected: string, month: string, year: string) => {
        if (dataReferentiel) {
            // console.log(dataReferentiel)
            try {
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;

                            if (fileTypeMatch) {
                                const statusKeys: (keyof FileStatusRefProps['status'])[] = ['loaded', 'checkfile', 'processed'];
                                const updatedStatus = { ...row.status };
                                statusKeys.forEach(key => {
                                    if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                                        updatedStatus[key] = 'pending';
                                    }
                                });

                                return {
                                    ...row,
                                    status: { ...updatedStatus, loaded: 'running' },
                                    error: undefined,
                                };
                            }
                            return row;
                        }),
                    };
                });

                const data = await uploadFileRefIngestion(file_type, file, buSelected!, '2024', '11');

                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
                            // console.log(row);
                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: {
                                        loaded: data.status.loaded,
                                        checkfile: data.status.checkfile,
                                        processed: data.status.processed,
                                    },
                                    final_filename: data.final_filename,
                                    error: data.error ? data.error : undefined,
                                    file_url: data.file_url
                                };
                            }
                            return row;
                        }),
                    };
                });
            } catch (error) {
                console.error('Error during file upload process:', error);
                setDataReferentiel((prevData) => {
                    if (!prevData || !buSelected || !prevData[buSelected]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;

                            if (fileTypeMatch) {
                                return {
                                    ...row,
                                    status: { ...row.status, loaded: 'error' },
                                    error: {
                                        title: 'Error',
                                        description: ''
                                    },
                                };
                            }
                            return row;
                        }),
                    };
                });
            }
        }
    };


    const rejectKPIs = async (file_asset: string, file_type: string, version?: string) => {
        const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];

        try {
            // Sauvegarder les infos nécessaires pour la requête
            const fileToReject = ingestionData?.[buSelected as string]?.find(row => {
                const typeMatch = row.file_type === file_type;
                const versionMatch = !row.version || row.version === version;
                return typeMatch && versionMatch;
            });

            const fileNameToDelete = fileToReject?.final_filename;

            // D'abord mettre à jour le state pour une réponse UI immédiate
            setIngestionData(prevData => {
                if (!prevData) {
                    return null;
                }

                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;

                        if (fileTypeMatch && versionMatch) {
                            return {
                                ...row,
                                status: {
                                    loaded: 'pending',
                                    checkfile: 'pending',
                                    checkKpis: 'pending',
                                    processed: 'pending'
                                },
                                error: undefined,
                                kpis: undefined,
                            };
                        }
                        return row;
                    })
                };
            });

            // Ensuite faire la requête delete si on a un filename
            if (fileNameToDelete) {
                const response = await fetch('/api/data_gov/ingestion/delete', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ file_name_sftp: fileNameToDelete })
                });

                const responseData = await response.json();

                if (!response.ok) {
                    throw new Error(`Failed to delete pending entry: ${response.statusText}`);
                }
            }

        } catch (error) {
            console.error('Error in rejectKPIs:', error);
        }
    };

    const uploadFileFromDropzoneMultiple = async ({
        bu,
        fileAsset,
        filesMeta,
        file
    }: UploadMultipleParams): Promise<void> => {
        const { fileType, version } = filesMeta[0];

        setIngestionData(prevData => {
            if (!prevData || !prevData[bu]) return prevData;

            const updatedRows = prevData[bu].map(row => {
                const matchedFile = filesMeta.find(f =>
                    row.file_type.toLowerCase() === f.fileType.toLowerCase() &&
                    (!row.version || row.version === f.version)
                );

                if (matchedFile) {
                    const statusKeys: (keyof FileStatusProps['status'])[] = ['loaded', 'checkfile', 'checkKpis', 'processed'];
                    const updatedStatus = { ...row.status };
                    statusKeys.forEach(key => {
                        if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                            updatedStatus[key] = 'pending';
                        }
                    });
                    return {
                        ...row,
                        status: { ...updatedStatus, loaded: 'running' },
                        error: undefined,
                    };
                }
                return row;
            });

            return { ...prevData, [bu]: updatedRows };
        });

        const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];

        const resultArray = await uploadFile(buSelected!, fileAsset, file, version, month, year) ?? []

        if (resultArray.length === 0) {
            console.error("Upload Error: The result array is empty.");

            setIngestionData((prevData) => {
                if (!prevData || !prevData[bu]) return prevData;

                const updatedRows = prevData[bu].map((row) => {
                    const matchedFile = filesMeta.find(
                        (f) =>
                            row.file_type.toLowerCase() === f.fileType.toLowerCase() &&
                            (!row.version || row.version === f.version)
                    );

                    if (matchedFile) {
                        return {
                            ...row,
                            status: {
                                ...row.status,
                                loaded: "error",
                                checkfile: "pending",
                                checkKpis: "pending",
                                processed: "pending",
                            },
                            error: {
                                title: "Upload Error: File Too Big.",
                                description: "Docker run out of memory.",
                            }
                        };
                    }
                    return row;
                });

                return { ...prevData, [bu]: updatedRows };
            });

            return;
        }

        setIngestionData(prevData => {
            if (!prevData || !prevData[bu]) return prevData;

            const updatedRows = [...prevData[bu]];

            for (const item of resultArray) {
                const fileTypeMatchIndex = updatedRows.findIndex(row =>
                    item.file_type.toLowerCase().includes(row.file_type.toLowerCase()) &&
                    (!row.version || row.version === item.version)
                );

                if (fileTypeMatchIndex !== -1) {
                    const currentRow = updatedRows[fileTypeMatchIndex];
                    const filteredKpis = Array.isArray(item.kpis)
                        ? item.kpis.filter((innerItem: any) => typeof innerItem === 'object')
                        : item.kpis;

                    updatedRows[fileTypeMatchIndex] = {
                        ...currentRow,
                        status: {
                            loaded: item.status.loaded,
                            checkfile: item.status.checkfile,
                            checkKpis: item.status.checkKpis,
                            processed: item.status.processed,
                        },
                        error: item.error ? item.error : undefined,
                        file_id: item.file_id,
                        kpis: filteredKpis,
                        tmp_bucket_url: item.tmp_bucket_url
                    };
                }
            }

            return {
                ...prevData,
                [bu]: updatedRows
            };
        });
    };


    const uploadFileFromDropzone = async (
        file_asset: string,
        file_type: string,
        file: File,
        version?: string
    ) => {
        if (ingestionData) {
            setIngestionData(prevData => {
                if (!prevData) return null;

                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;

                        if (fileTypeMatch && versionMatch) {
                            const statusKeys: (keyof FileStatusProps['status'])[] = ['loaded', 'checkfile', 'checkKpis', 'processed'];
                            const updatedStatus = { ...row.status };
                            statusKeys.forEach(key => {
                                if (updatedStatus[key] === 'error' || updatedStatus[key] === 'finished' || updatedStatus[key] === 'running') {
                                    updatedStatus[key] = 'pending';
                                }
                            });
                            return {
                                ...row,
                                status: { ...updatedStatus, loaded: 'running' },
                                error: undefined,
                            };
                        }
                        return row;
                    })
                };
            });
        }

        try {
            const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];
            const resultArray = await uploadFile(file_asset, file_type, file, version, month, year) ?? []


            if (resultArray.length === 0) {
                console.error("Upload Error: The result array is empty.");

                setIngestionData((prevData) => {
                    if (!prevData || !prevData[buSelected!]) return prevData;

                    return {
                        ...prevData,
                        [buSelected as string]: prevData[buSelected as string].map((row) => {
                            const fileTypeMatch = row.file_type === file_type;
                            const versionMatch = !row.version || row.version === version;

                            if (fileTypeMatch && versionMatch) {
                                return {
                                    ...row,
                                    status: {
                                        ...row.status,
                                        loaded: "error",
                                        checkfile: "pending",
                                        checkKpis: "pending",
                                        processed: "pending",
                                    },
                                    error: {
                                        title: "Upload Error: File Too Big",
                                        description: "Docker run out of memory.",
                                    }
                                };
                            }
                            return row;
                        }),
                    };
                });
                return;
            }


            setIngestionData(prevData => {
                if (!prevData || !prevData[buSelected!]) return prevData;

                const updatedRows = [...prevData[buSelected!]];
                for (const item of resultArray) {
                    const filteredKpis = Array.isArray(item.kpis)
                        ? item.kpis.filter((innerItem: any) => typeof innerItem === 'object')
                        : item.kpis;
                    const fileId = item.file_id || null;

                    const fileTypeMatchIndex = updatedRows.findIndex(row => item.file_type.toLowerCase().includes(row.file_type.toLowerCase()) && (!row.version || row.version === version));

                    if (fileTypeMatchIndex !== -1) {
                        const currentRow = updatedRows[fileTypeMatchIndex];
                        updatedRows[fileTypeMatchIndex] = {
                            ...currentRow,
                            status: {
                                loaded: item.status.loaded,
                                checkfile: item.status.checkfile,
                                checkKpis: item.status.checkKpis,
                                processed: item.status.processed,
                            },
                            error: item.error ? item.error : undefined,
                            file_id: fileId,
                            kpis: filteredKpis,
                            tmp_bucket_url: item.tmp_bucket_url,
                        };
                    }
                }

                return {
                    ...prevData,
                    [buSelected as string]: updatedRows
                };
            });
        } catch (error) {
            console.error("File upload failed:", error);
            setIngestionData(prevData => {
                if (!prevData || !prevData[buSelected!]) return prevData;

                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;

                        if (fileTypeMatch && versionMatch) {
                            return {
                                ...row,
                                status: {
                                    ...row.status,
                                    loaded: 'error'
                                }
                            };
                        }
                        return row;
                    })
                };
            });
        }
    };

    const updateIngestionDataWithResult = (
        data: any,
        file_type: string,
        version?: string
    ) => {
        setIngestionData(prevData => {
            if (!prevData) return null;

            return {
                ...prevData,
                [buSelected as string]: prevData[buSelected as string].map(row => {
                    const fileTypeMatch = row.file_type === file_type;
                    const versionMatch = !row.version || row.version === version;

                    if (fileTypeMatch && versionMatch) {
                        return {
                            ...row,
                            status: {
                                loaded: data.status.loaded,
                                checkfile: data.status.checkfile,
                                checkKpis: data.status.checkKpis,
                                processed: data.status.processed,
                            },
                            error: data.error ? data.error : undefined,
                        };
                    }
                    return row;
                })
            };
        });
    };


    const confirmFileAfterKpis = async (file_asset: string, file_type: string, version?: string, monthYearSelected?: string, tmp_bucket_url?: string[]) => {
        const [month, year] = monthYearSelected ? monthYearSelected.split('/') : [undefined, undefined];

        try {
            setIngestionData(prevData => {
                if (!prevData) return null;

                return {
                    ...prevData,
                    [buSelected as string]: prevData[buSelected as string].map(row => {
                        const fileTypeMatch = row.file_type === file_type;
                        const versionMatch = !row.version || row.version === version;

                        if (fileTypeMatch && versionMatch) {
                            const updatedStatus = { ...row.status };
                            return {
                                ...row,
                                status: { ...updatedStatus, checkKpis: 'finished', processed: 'running' },
                                error: undefined,
                            };
                        }
                        return row;
                    })
                };
            });

            if (!tmp_bucket_url || tmp_bucket_url.length === 0) {
                const data = await confirmFile(file_asset, file_type, version, month, year, undefined);
                if (!data) return;

                updateIngestionDataWithResult(data, file_type, version);
                return;
            }
            const delay = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));

            // Au lieu de Promise.all
            const results = [];
            for (let i = 0; i < tmp_bucket_url.length; i++) {
                // Ajouter un délai de 3 secondes sauf pour le premier appel
                if (i > 0) {
                    await delay(2000);
                }

                const result = await confirmFile(
                    file_asset,
                    file_type,
                    version,
                    month,
                    year,
                    tmp_bucket_url[i]
                );
                results.push(result);
            }

            const lastValidResult = results.filter(Boolean).pop();
            if (!lastValidResult) return;

            updateIngestionDataWithResult(lastValidResult, file_type, version);
        } catch (error) {
            console.log(error);
            return null;
        }
    };


    // useEffect(() => {
    //     if (dataQuality?.fields?.asset) {
    //         const dates = Object.keys(dataQuality.fields.assets);
    //         setDateOptions(dates);

    //     }
    // }, [dataQuality, dateSelected]);

    useEffect(()=>{
        if(authorisedBUOptions){
            setBuOptions(authorisedBUOptions);
            // setDateOptions(['2024','2025'])
        }
    },[authorisedBUOptions])

    // useEffect(() => {
    //     if (dataQuality?.data) {
    //         let totalLateFiles = 0;

    //         Object.keys(dataQuality.data).forEach(year => {
    //             const assets = dataQuality.data[year];
    //             Object.keys(assets).forEach(asset => {
    //                 const assetData = assets[asset];
    //                 Object.keys(assetData).forEach(useCase => {
    //                     const useCaseData = assetData[useCase];
    //                     const lateFiles = useCaseData?.late ?? 0;

    //                     totalLateFiles += lateFiles;
    //                 });
    //             });
    //         });
    //         setLateFilesNumber(totalLateFiles);
    //     }
    // }, [dataQuality]);

    useEffect(() => {
        if (availableYears && availableYears.length > 0) {
            setDateSelected(availableYears[0]);
        }
    }, [availableYears]);

    useEffect(() => {
        if (buOptions.length > 0 && !buSelected) {
            setBuSelected(buOptions[0]);
        }
    }, [buOptions]);

    return (
        <DataGouvContext.Provider value={{
            buSelected,
            setBuSelected,
            dateSelected,
            setDateSelected,
            dataQuality,
            buOptions,
            dateOptions,
            getQualityDetailForBu,
            lateFilesNumber,
            ingestionData,
            uploadFileFromDropzone,
            uploadFileFromDropzoneMultiple,
            useCase,
            setUseCase,
            setMonthYearSelected,
            monthYearSelected,
            rejectKPIs,
            app,
            setApp,
            confirmFileAfterKpis,
            loaderIngestionData,
            dataReferentiel,
            uploadFileFromDropzoneRef,
            uploadFileFromDropzoneRefIngestion,
            dataLineage,
            nodes,
            edges,
            getTables,
            getEdges,
            loaderOverview,
            loaderLineageData,
            activeTable,
            setActiveTable,
            loaderReferentielData,
            userPermissions,
            authorisedApps,
            setAuthorisedApps,
            authorisedBUOptions,
            availableYears,
            loadingDownloads,
            handleDownload
        }}>
            {children}
        </DataGouvContext.Provider>
    );
};

export const useDataGouv = () => {
    const context = useContext(DataGouvContext);
    if (!context) {
        throw new Error('useDataGouv must be used within a DataGouvProvider');
    }
    return context;
};