import React, { ComponentType, ReactNode, SVGProps, useEffect, useRef, useState } from 'react';
import { FailedIcon, HelpIcon } from './icons/Icons';
import ReactDOM from 'react-dom';
import { SVGIcon, SVGIconsType } from '../../assets';
import { format } from 'date-fns';
import { Tabs } from '../../assets';
import { useActiveTabs } from '../../providers';
import { DataGouvHome } from './DataGouvHome';
import { useDataGouv } from './providers/DataGouvProvider';
import { transformKeyToActiveTable } from './DataTable';

import { dataGouvernance } from "../../assets"

export function formatLastUpdated(date?: string | null): string {
  if (date === 'Not available') return date;
  if (!date) return '2024-11-30';
  try {
    return format(new Date(date), 'yyyy-MM-dd');
  } catch (error) {
    console.error('Invalid date format:', date);
    return '2024-11-30';
  }
}

export const CheckIcon: React.FC = () => (
  <div className='flex items-center justify-center w-fit flex-row gap-1 bg-[#ECFDF3] rounded-2xl px-2 py-1'>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-[#12B76A]" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
    </svg>
    <p className='text-xs text-[#027A48]'>
      Success
    </p>
  </div>
);

export const CrossIcon: React.FC = () => (
  <div className='flex items-center justify-center w-fit flex-row gap-1 bg-[#FEF3F2] rounded-2xl px-2 py-1'>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-3 w-3 text-[#F04438]"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={3}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
    <p className='text-xs text-[#B42318] border-[#B42318] border-b border-dashed'>
      Failed
    </p>
  </div>
);
export const PendingIcon: React.FC = () => (
  <div className="w-full h-5 cursor-pointer flex items-center px-7 py-3">
    <svg
      width="25"
      height="2"
      viewBox="0 0 25 2"
      xmlns="http://www.w3.org/2000/svg"
      className="block"
    >
      <rect width="30" height="2" rx="2" fill="#CFD0D7" />
    </svg>
  </div>
);


export const RunningIcon: React.FC = () => (
  <div className='flex items-center justify-center w-fit flex-row gap-1 bg-[#FEF0C7] rounded-2xl px-2 py-1'>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_4077)">
        <path d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" stroke="#DC6803" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3_4077">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <p className='text-xs text-[#DC6803]'>
      Running
    </p>
  </div>
);

export const DelayedIcon: React.FC = () => (
  <div className='flex items-center justify-center w-fit flex-row gap-1 bg-[#FEF0C7] rounded-2xl px-2 py-1'>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_4077)">
        <path d="M6 3V6L8 7M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z" stroke="#DC6803" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3_4077">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <p className='text-xs text-[#DC6803]'>
      Delayed
    </p>
  </div>
);

const getIcon = (value: number) => {
  if (value === 1) {
    return <CheckIcon />;
  } else if (value === 0) {
    return <PendingIcon />;
  } else if (value === -1) {
    return <CrossIcon />;
  } else {
    return null;
  }
};

export interface SummaryBuProps {
  title: string;
  tables: {
    name: string;
    last_updated: string;
    file_not_received: number;
    file_not_received_messages: string;
    file_integrity: number;
    file_integrity_messages: string;
    data_integrity: number;
    data_integrity_messages: string;
    mapping_coverage: number;
    mapping_coverage_messages: string;
  }[];
  setApp: React.Dispatch<React.SetStateAction<string>>;
  Icon: {
    icon: SVGIconsType;
    size?: string;
    className?: string;
    wrapper?: string;
    fill?: string;
  };
  tab: Tabs;
}

type TableKeys = 'file_not_received' | 'file_integrity' | 'data_integrity' | 'mapping_coverage';
type MessageKeys = 'file_not_received_messages' | 'file_integrity_messages' | 'data_integrity_messages' | 'mapping_coverage_messages';

export const SummaryBu: React.FC<SummaryBuProps> = ({ title, tables, setApp, Icon, tab }) => {
  const helpIconRefs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);
  const [failedNumber, setFailedNumber] = useState<number | null>(null);
  const { openTab, closeTab } = useActiveTabs();
  const { setActiveTable } = useDataGouv();

  const titleToTabMap: Record<string, any> = dataGouvernance.tabs.reduce((map, tab) => {
          map[tab.title] = tab;
          return map;
  }, {} as Record<string, any>);

  const defaultTab = {
    id: 50,
    appId: "GenAI/SecureChatGPT",
    title: "Overview",
    icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
    description: [],
    app: () => <DataGouvHome />,
  };

  useEffect(() => {
    if (tables.length > 0) {
      const totalFailed = tables.reduce((count, table) => {
        const fieldsToCheck = [
          table.file_not_received,
          table.file_integrity,
          table.data_integrity,
          table.mapping_coverage,
        ];

        const failedCount = fieldsToCheck.filter(value => value === -1).length;

        return count + failedCount;
      }, 0);

      setFailedNumber(totalFailed);
    }
  }, [tables]);

  const Tooltip: React.FC<{ text: ReactNode; targetRef: React.RefObject<HTMLDivElement> }> = ({ text, targetRef }) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [coords, setCoords] = useState<{ left: number; top: number; width: number; height: number }>({
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    });
    const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
      const handleMouseEnter = () => setVisible(true);
      const handleMouseLeave = () => setVisible(false);
      const node = targetRef.current;
      if (node) {
        node.addEventListener('mouseenter', handleMouseEnter);
        node.addEventListener('mouseleave', handleMouseLeave);
      }
      return () => {
        if (node) {
          node.removeEventListener('mouseenter', handleMouseEnter);
          node.removeEventListener('mouseleave', handleMouseLeave);
        }
      };
    }, [targetRef]);

    useEffect(() => {
      if (visible && targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        setCoords({
          left: rect.left + window.pageXOffset,
          top: rect.top + window.pageYOffset,
          width: rect.width,
          height: rect.height,
        });
      }
    }, [visible, targetRef]);

    if (!visible) return null;

    return ReactDOM.createPortal(
      <div
        ref={tooltipRef}
        className="absolute bg-black text-white text-xs rounded-md px-3 py-1 shadow-lg text-center"
        style={{
          zIndex: 9999,
          maxWidth: '200px',
          wordWrap: 'break-word',
          left: coords.left + coords.width / 2,
          top: coords.top,
          position: 'absolute',
          transform: 'translate(-50%, -110%)',
        }}
      >
        {text}
        <span className="rotate-180 absolute w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent border-b-4 border-b-black left-1/2 transform -translate-x-1/2 top-full" />
      </div>,
      document.body
    );
  };

  const columns = [
    { label: 'Table\u00A0name', tooltip: 'Name of the table' },
    { label: 'Last\u00A0Updated', tooltip: 'The date when the table was last updated.' },
    { label: 'File\u00A0not\u00A0received', tooltip: 'This test checks when you last submitted your data file that feeds a given table in the SDH. The test fails if you are late in your expected submission.' },
    { label: 'File\u00A0Integrity', tooltip: 'This test checks if the file you uploaded via SFTP adheres to our instructions for the file name, column names, and data types for each column.' },
    { label: 'Data\u00A0Integrity', tooltip: 'This test checks if your submitted data complies with historical standards (i.e., if your submitted data contains erroneous values with respect to historical standards that are supposed to be fixed and valid).' },
    { label: 'Mapping\u00A0Coverage', tooltip: 'This test checks if your mapping files for each use case cover the IDs present in your different ingestion files.' },
  ];

  return (
    <div className="relative max-h-[400px] h-[400px] scale-[1.01] overflow-hidden flex items-center gap-2 border border-[#F5F5F5] flex-col bg-white text-black rounded-xl shadow-md cursor-pointer hover:shadow-lg transition-shadow">
      <div className="flex flex-row justify-between w-full p-1">
        <div className="flex flex-row w-full items-center px-1 py-2">
          <SVGIcon {...Icon} />
          <h2 className="flex text-xl font-trendaSemiBold text-black">{title}</h2>
        </div>
        <div className="flex w-full justify-end flex-row gap-1 px-2 text-[#F04438] items-center">
          {failedNumber && failedNumber > 0 ? (
            <>
              <FailedIcon />
              {failedNumber}
              <h2 className="">Failed</h2>
            </>
          ) : (
            <p className='text-[#758088]'>No alerts</p>
          )}
        </div>
      </div>
      <div className="w-full overflow-y-auto no-scrollbar max-h-[300px]">
        <table className="w-full text-left table-fixed border-collapse">
          <thead>
            <tr className="bg-[#F9FAFB] border-t border-b border-gray-200">
              {columns.map((column, index) => {
                if (!helpIconRefs.current[index]) {
                  helpIconRefs.current[index] = React.createRef<HTMLDivElement>();
                }
                const ref = helpIconRefs.current[index];
                return (
                  <th
                    key={index}
                    className="px-3 py-4 text-xs font-medium text-[#667085] sticky top-0 bg-[#F9FAFB] z-10" 
                  >
                    <div className="flex items-center gap-1">
                      {column.label}
                      <div ref={ref} className="relative inline-block">
                        <HelpIcon className="cursor-pointer" />
                        <Tooltip text={column.tooltip} targetRef={ref} />
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tables.map((table, index) => {
              const isFailing =
                table.file_not_received === -1 ||
                table.file_integrity === -1 ||
                table.data_integrity === -1 ||
                table.mapping_coverage === -1;

              return (
                <tr
                  key={index}
                  className={isFailing ? 'bg-[#FFF8F8]' : 'bg-white'}
                >
                  <td className="font-500 px-3 py-3 text-xs text-[#101828] border-b">
                    <span className="inline-block border-b border-dotted border-current">
                      {table.name.replace(' ', '\u00A0')}
                    </span>
                  </td>
                  <td className="px-3 py-3 text-xs text-[#667085] border-b">
                    {formatLastUpdated(table.last_updated)}
                  </td>
                  {(['file_not_received', 'file_integrity', 'data_integrity', 'mapping_coverage'] as TableKeys[]).map((key, colIndex) => {
                    const messageKey = `${key}_messages` as MessageKeys;
                    const value = table[key];
                    const message = table[messageKey];
                    const iconRef = React.createRef<HTMLDivElement>();

                    return (
                      <td key={colIndex} className="px-3 py-3 text-xs text-[#667085] border-b">
                        <div className="relative inline-block">
                          {value === -1 && message ? (
                            <div
                              ref={iconRef}
                              className="cursor-pointer"
                              onClick={() => {
                                setApp('Quality');
                                closeTab(titleToTabMap[title].id)
                                openTab(tab ?? defaultTab);
                                setActiveTable(transformKeyToActiveTable(key));
                              }}
                            >
                              {getIcon(value)}
                              <Tooltip text={message} targetRef={iconRef} />
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                openTab(tab ?? defaultTab);
                                setActiveTable(transformKeyToActiveTable(key));
                              }}
                            >
                              {getIcon(value)}
                            </div>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};