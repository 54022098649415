import { useEffect } from "react";
import "./App.css";
import { Layout } from "./layout";
import { CreditProvider, useAuth } from "./providers";
import { HomeAppPreview } from "./components/HomeAppPreview";
import { ChatGPTProvider } from "./pages/ChatGPT/providers";
import { MultiDocsProvider } from "./pages/ChatGPT/providers/MultiDocsProvider";
import { DataGouvProvider } from "./pages/DataGouvernance/providers/DataGouvProvider";
import { MultiModalProvider } from "./pages/ChatGPT/providers/MultiModalProvider";



function App() {
  const { isAuthenticated, login, updateToken, token, fetchJWT } = useAuth();

  useEffect(() => {
    fetchJWT()
  }, []);

  useEffect(() => {
    if (token) {
      const intervalId = setInterval(() => {
        updateToken();
      }, 4 * 60 * 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [token]);

  if (!isAuthenticated) {
    return <div className="flex flex-col justify-center items-center h-screen bg-text-primary-dark">
      <div className="flex justify-center">
        <HomeAppPreview showTitle={true} titleOpacity={100} isAbsolute={false} />
      </div>
      <div className={`pl-8 `}>
        <button className="py-3 px-4 mt-4 border rounded-xl text-white font-medium text-lg border-text-primary-light bg-text-primary-light hover:text-text-primary-light hover:bg-white" onClick={login}>Login</button>
      </div>
    </div>
  } else {
    return (
      <CreditProvider>
        <ChatGPTProvider>
          <MultiDocsProvider>
            <MultiModalProvider>
                <Layout />
            </MultiModalProvider>
          </MultiDocsProvider>
        </ChatGPTProvider>
      </CreditProvider>
    )

  }
}

export default App;
