import React, { useEffect, useRef, useState } from "react";
import { FileRow } from "./FileChecksTable";
import { FileData } from "../hooks/useDataIngestion";
import { ButtonIngestion } from "./ButtonIngestion";
import { useDataGouv } from "../providers/DataGouvProvider";
import './animation.css'
import { useAuth } from "../../../providers";
import { SVGIcon } from "../../../assets";
import { LoaderSpinner } from "./Icons";

interface LinkedFilesProps {
  linkedFiles: FileData[];
  mainFileName: string;
  version?: string;
  openModalRowId: string | null;
  setOpenModalRowId: (id: string | null) => void;
  rowId: string;
}

export const LinkedFileDropdown: React.FC<LinkedFilesProps> = ({
  linkedFiles,
  mainFileName,
  openModalRowId,
  setOpenModalRowId,
  rowId,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { 
    buSelected,
    uploadFileFromDropzoneMultiple,
    ingestionData,
    handleDownload,
    loadingDownloads 
  } = useDataGouv();
  const { token } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const [blink, setBlink] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const showModal = rowId === openModalRowId;

  const [linkedToFileUrl, setLinkedToFileUrl] = useState<string | null>(null)

  const handleToggleModal = () => {
    if (showModal) {
      setOpenModalRowId(null);
    } else {
      setOpenModalRowId(rowId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (showModal) {
          setOpenModalRowId(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  const [loaderDownload, setLoaderDownload] = useState(false);
  const handleDownloadTemplate = async () => {
    setLoaderDownload(true);
    try {
      const response = await fetch(
        `/api/data_gov/ingestion/template?file_asset=${buSelected}&file_type=${mainFileName}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Download failed: ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers.get('content-disposition');
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename="(.+)"/);
      const filename = filenameMatch
        ? filenameMatch[1]
        : `template-${buSelected}-${mainFileName}.xlsx`;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderDownload(false);
    }
  };

  useEffect(()=>{
    const linkedToFileUrl = linkedFiles.find(elem => elem.file_type === mainFileName)?.url
    setLinkedToFileUrl(linkedToFileUrl!)
  },[mainFileName])
  

  const buData = ingestionData![buSelected!] || [];
  const currentFile =
    buData.find(
      (file) =>
        file.file_type === mainFileName || file.file_type === 'CAPEX_FORECAST'
    ) || {
      status: {
        loaded: 'pending',
        checkfile: 'pending',
        checkKpis: 'pending',
        processed: 'pending',
      },
      error: null,
      url: null,
      kpis: null,
      uploaded_by: null,
      date: null,
      check_ref_messages: [],
    };

  useEffect(() => {
    if (currentFile.status.loaded === 'running' && !file) {
      setBlink(true);
      const timer = setTimeout(() => setBlink(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [currentFile.status.loaded, file]);

  useEffect(() => {
    if (file && linkedFiles.length > 0 && buSelected) {
      const filesMeta = linkedFiles.map((f) => ({
        fileType: f.file_type,
        version: f.version,
      }));
      uploadFileFromDropzoneMultiple({
        bu: buSelected,
        fileAsset: mainFileName,
        filesMeta: filesMeta,
        file: file,
      });
    }
  }, [file]);

  return (
    <>
      <tr className={`${blink ? 'blink-border' : ''}`}>
        <td
          colSpan={6}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="py-2 cursor-pointer border-b border-t"
        >
          <div className="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#686868"
              viewBox="0 0 24 24"
              className={`${!dropdownOpen && '-rotate-90'}`}
            >
              <path
                fill="black"
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="text-sm">{mainFileName}</p>
          </div>
        </td>
        <td colSpan={2} className="px-4 py-2 border-b border-t">
          <ButtonIngestion
            onClick={() => setDropdownOpen(!dropdownOpen)}
            status={currentFile.status}
            setFile={setFile}
          />
        </td>
        <td className="relative px-4 py-1.5 text-sm text-gray-800">
          <div
            className="flex flex-col items-center justify-center bg-[#EEEEEE] rounded-md h-[33px] w-[33px] p-1 gap-1 cursor-pointer"
            onClick={handleToggleModal}
          >
            <div className="rounded-full w-1 h-1 bg-[#686868]" />
            <div className="rounded-full w-1 h-1 bg-[#686868]" />
            <div className="rounded-full w-1 h-1 bg-[#686868]" />
            {showModal && (
              <div
                style={{ zIndex: 9999 }}
                ref={modalRef}
                className="pb-2 absolute flex justify-start border-2 border-[#F6F6F6] flex-col items-start top-[40px] shadow-sm right-5 bg-white rounded-xl w-[250px] py-3 px-2"
              >
                <div
                  className="hover:bg-[#EEEEEE] flex flex-row gap-3 w-full items-center cursor-pointer rounded-xl py-2 px-3"
                  onClick={handleDownloadTemplate}
                >
                  <SVGIcon icon="FOLDER_DOWNLOAD_ICON" />
                  <p className="w-full font-medium">Download Template</p>
                </div>
                {linkedToFileUrl && linkedToFileUrl.length > 0 && (
                  <div
                    className="hover:bg-[#EEEEEE] py-2 px-3 rounded-xl flex flex-row gap-3 w-full justify-start items-center cursor-pointer"
                    onClick={() => handleDownload(linkedToFileUrl)}
                  >
                    <SVGIcon icon="FOLDER_DOWNLOAD_ICON" />
                    <p className="w-full font-medium">Download File</p>
                    {loadingDownloads[linkedToFileUrl] === true && (
                      <LoaderSpinner />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </td>
      </tr>
      {dropdownOpen &&
        linkedFiles.map((fileData, index) => (
          <FileRow
            key={index}
            fileType={fileData.file_type}
            version={fileData.version}
            isDropdown={true}
            fileLinked={file}
            rowId={`unlinked-${fileData.file_type}-${fileData.version || ''}-${index}`}
            openModalRowId={openModalRowId}
            setOpenModalRowId={setOpenModalRowId}
            file_id={fileData.file_id}
          />
        ))}
    </>
  );
};

export const LinkedBudgetDropdown: React.FC<LinkedFilesProps> = ({ linkedFiles, mainFileName,openModalRowId, setOpenModalRowId,rowId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [blink, setBlink] = useState<boolean>(false);
  const currentFile = {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
  };

  useEffect(()=>{
    if(linkedFiles && linkedFiles.length > 0){
      const someFileIsLoading = linkedFiles.some(elem => elem.status.loaded === 'running')
      if(someFileIsLoading){
        setDropdownOpen(true)
        setBlink(true);
        const timer = setTimeout(() => setBlink(false), 5000);
        return () => clearTimeout(timer);
      } 
    }
  },[linkedFiles])
  return (
    <>
      <tr className={`${blink ? 'blink-border' : ''}`}>
        <td colSpan={9} onClick={() => setDropdownOpen(!dropdownOpen)} className="py-3 cursor-pointer border-b border-t">
          <div className="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#686868"
              viewBox="0 0 24 24"
              className={`${!dropdownOpen && '-rotate-90'}`}
            >
              <path
                fill="black"
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="text-sm">{mainFileName}</p>
          </div>
        </td>
      </tr>
      {dropdownOpen &&
        linkedFiles.map((fileData, index) => (
          <FileRow 
              key={index} 
              fileType={fileData.file_type}
              version={fileData.version} 
              isDropdown={true}
              isMultiple={true} 
              rowId={`unlinked-${fileData.file_type}-${fileData.version || ''}-${index}`}
              openModalRowId={openModalRowId}
              setOpenModalRowId={setOpenModalRowId}
              file_id={fileData.file_id}
           />
        ))}
    </>
  );
};