import React, { useEffect, useState } from 'react';
import { SelectorFileType, SelectorFileTypeRef} from './Selector';
import { useDataGouv } from '../providers/DataGouvProvider';
import { ButtonIngestion, ButtonIngestionProps, CustomButton } from './ButtonIngestion';
import { FileStatusProps } from './FileStatus';
import { SVGIcon } from '../../../assets';
import { FileData } from '../hooks/useDataIngestion';
import { v4 as uuidv4 } from 'uuid';
import { FileWithId } from './FileLoaderTable';

interface FileLoaderTable {
  files: FileWithId[];
  setFiles: React.Dispatch<React.SetStateAction<FileWithId[] | []>>;
}

export const FileTableReferentiel: React.FC<FileLoaderTable> = ({files, setFiles}) => {

  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-100" style={{ backgroundColor: '#EEEEEE' }}>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File name</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File type</th>
            {/* <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Uploaded by</th> */}
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <FileRow key={file.id} index={index} fileName={file.file.name} setFiles={setFiles} files={files} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface FileRowProps {
  fileName: string;
  setFiles: React.Dispatch<React.SetStateAction<FileWithId[] | []>>;
  files: FileWithId[];
  index: number
}

const FileRow: React.FC<FileRowProps> = ({ fileName, setFiles, files, index }) => {
    const {buSelected, uploadFileFromDropzoneRef, uploadFileFromDropzoneRefIngestion, dataReferentiel} = useDataGouv()
    const [selectedFileType, setSelectedFileType] = useState<string | null>(null);


    useEffect(() => {
      if (!fileName || !buSelected || !dataReferentiel) return;
    
      const buOptions = dataReferentiel[buSelected] || [];
    
      const fileTypeOptions = Array.from(
        new Set(buOptions.map((option) => option.file_type))
      );
      // console.log(fileTypeOptions);
    
      const normalizedFileTypeOptions = fileTypeOptions.map((type) =>
        type.replace(/_/g, '').toLowerCase()
      );
    
      const normalizedFileName = fileName.replace(/_/g, '').toLowerCase();
    
      const matchingFileType = normalizedFileTypeOptions.find((type) =>
        normalizedFileName.includes(type)
      );
    
      if (matchingFileType && matchingFileType !== selectedFileType) {
        const originalFileType = fileTypeOptions.find(
          (type) => type.replace(/_/g, '').toLowerCase() === matchingFileType
        );
        setSelectedFileType(originalFileType!);
      }
    }, [fileName, buSelected]);
    
    
    const handleFileTypeChange = (fileType: string) => {
        setSelectedFileType(fileType);
    };

    const handleRemoveFile = () => {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
    };    

    const fileType = dataReferentiel?.[buSelected!]?.find((obj) => obj.file_type === selectedFileType)?.file_type || "";

    const url = dataReferentiel?.[buSelected!]?.find((obj) => obj.file_type === selectedFileType)?.file_url || "";

    const handleConfirmClick = () => {
      if (!buSelected || !selectedFileType) return
      if(fileType === 'MAPPING_LOCAL' || fileType === 'MAPPING_USECASE'){
        uploadFileFromDropzoneRefIngestion(fileType, files[index].file, buSelected!,'11', '2024')
      }
      else{
        uploadFileFromDropzoneRef(fileType, files[index].file, url);
      }
      handleRemoveFile()
    };     

  return (
    <tr className="border-b border-gray-200 bg-white">
      <td className="px-4 py-1.5 text-sm text-gray-800">{fileName}</td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <SelectorFileTypeRef options={dataReferentiel} buName={buSelected!}   
            onChange={(e) => handleFileTypeChange(e.target.value)}
            setSelectedFileType={setSelectedFileType}
            value={selectedFileType}
        />
      </td>
      {/* <td className="px-4 py-1.5 text-sm text-gray-800">John Smith</td> */}
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <CustomButton text='Confirm file info' onClick={handleConfirmClick}/>
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800 cursor-pointer" onClick={handleRemoveFile}>
        <SVGIcon icon='TRASH_ICON'/>
      </td>
    </tr>
  );
};