import React, { createContext, useContext, useEffect, useState } from "react";
import { Menu, Tabs, getAppMenu, homeTab, isSection, menuData } from "../../assets";
import { useAuth } from "../Auth";
import { useToggle } from "@uidotdev/usehooks";
import { useDataGouv } from "../../pages/DataGouvernance/providers/DataGouvProvider";

type TabState = {
  tabs: Tabs[];
  activeTabId: number | null;
};

type ActiveTabsContextType = {
  allTabs: Tabs[];
  tabState: TabState;
  openTab: (tab: Tabs) => void;
  closeTab: (tabId: number) => void;
  setActiveTab: (tabId: number) => void;
  menu: Menu | null;
  returnFav: (ids: number[]) => Tabs[];
  closeAllTabs: () => void;
  isSidebarOpen: boolean;
  toggleSidebar: (newValue?: boolean) => void
};


const getAllTabs = (menu: Menu): Tabs[] => {
  return menu.reduce((acc, item) => {
    if (isSection(item)) {
      return [...acc, ...item.tabs];
    } else {
      acc.push(item);
      return acc;
    }
  }, [] as Tabs[]);
};

const ActiveTabsContext = createContext<ActiveTabsContextType | undefined>(
  undefined
);

export const ActiveTabsProvider = ({ children }: React.PropsWithChildren) => {
  const [isSidebarOpen, toggleSidebar] = useToggle(true);
  const {loaderIngestionData} = useDataGouv()
  const [allTabs, setAllTabs] = useState<Tabs[]>([homeTab])
  const defaultTab = allTabs.find((tab) => tab.id === 9);
  const [tabState, setTabState] = useState<TabState>({
    tabs: defaultTab ? [homeTab] : [],
    activeTabId: defaultTab ? 9 : null,
  });
  const { token, tokenInfo } = useAuth()
  const [menu, setMenu] = useState<Menu | null>(null)

  useEffect(() => {
    if (token && tokenInfo) {
      const rights = tokenInfo.app
      const menu = getAppMenu(rights)
      setMenu(menu)
      const aT = getAllTabs(menu)
      setAllTabs(aT)
    }
  }, [token, tokenInfo])

  const closeAllTabs = () => {
    setTabState({tabs: [], activeTabId: null})
  }

  const openTab = (tab: Tabs) => {
    if(loaderIngestionData) return
    setTabState((prevState) => {
      if (prevState.tabs.some((t) => t.id === tab.id)) {
        return { ...prevState, activeTabId: tab.id };
      }
      return { tabs: [...prevState.tabs, tab], activeTabId: tab.id };
    });
  };

  const closeTab = (tabId: number) => {
    setTabState((prevState) => {
      const newTabs = prevState.tabs.filter((t) => t.id !== tabId);
      let newActiveTabId = prevState.activeTabId;

      if (tabId === prevState.activeTabId) {
        newActiveTabId = newTabs.length > 0 ? newTabs[0].id : null;
        // Si aucun onglet, charger l'onglet par défaut (ID 0)
        if (newActiveTabId === null) {
          const defaultTab = allTabs.find((item) => item.id === 9);
          if (defaultTab) {
            newTabs.push(defaultTab);
            newActiveTabId = defaultTab.id;
          }
        }
      }

      return { tabs: newTabs, activeTabId: newActiveTabId };
    });
  };
  const returnFav = (ids: number[]) => {
    let result: Tabs[] = [];

    menu?.forEach(item => {
      if ('id' in item && ids.includes(item.id)) {
        result.push(item);
      }

      if ('tabs' in item) {
        item.tabs.forEach(tab => {
          if (ids.includes(tab.id)) {
            result.push(tab);
          }
        });
      }
    });

    return result;
  }
  const setActiveTab = (tabId: number) => {
    setTabState((prevState) => ({ ...prevState, activeTabId: tabId }));
  };

  return (
    <ActiveTabsContext.Provider
      value={{ allTabs, tabState, openTab, closeTab, setActiveTab, menu, returnFav, closeAllTabs, isSidebarOpen, toggleSidebar }}
    >
      {children}
    </ActiveTabsContext.Provider>
  );
};

export const useActiveTabs = () => {
  const context = useContext(ActiveTabsContext);
  if (!context) {
    throw new Error(
      "useActiveTabs doit être utilisé à l’intérieur d’un ActiveTabsProvider"
    );
  }
  return context;
};
