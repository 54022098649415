import React, { useEffect, useState } from 'react';
import { SelectorFileType, SelectorFileVersion } from './Selector';
import { useDataGouv } from '../providers/DataGouvProvider';
import { ButtonIngestion, ButtonIngestionProps, CustomButton } from './ButtonIngestion';
import { FileStatusProps } from './FileStatus';
import { SVGIcon } from '../../../assets';
import { FileData } from '../hooks/useDataIngestion';


enum FilenameContains {
  CAPEX = 'CAPEX',
  CAPEX_FORECAST = 'CAPEX_FORECAST',
  CPXFORECAST = 'CPXFORECAST',
  MAPPING_LOCAL = 'MAPPING_LOCAL',
  GENERAL_LEDGER = 'GENERAL_LEDGER',
  SUPPLIER_OPEX = 'SUPPLIER_OPEX',
  CUSTOMER_REVENUES = 'CUSTOMER_REVENUES',
  BUDGET = 'BUDGET',
  RECEIVABLES =  'RECEIVABLES'
}

export interface FileWithId {
  id: string;
  file: File
}
interface FileLoaderTable {
  files: FileWithId[];
  setFiles: React.Dispatch<React.SetStateAction<FileWithId[] | []>>;
}

export const FileTable: React.FC<FileLoaderTable> = ({files, setFiles}) => {
  useEffect(()=>{
    console.log(files)
  },[files])
  return (
    <div className="w-full border border-gray-200 rounded-md overflow-hidden">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-100" style={{ backgroundColor: '#EEEEEE' }}>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File name</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">File type</th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Version</th>
            {/* <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Uploaded by</th> */}
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600"></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <FileRow key={file.id} index={index} fileName={file.file.name} setFiles={setFiles} files={files} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface FileRowProps {
  fileName: string;
  setFiles: React.Dispatch<React.SetStateAction<FileWithId[] | []>>;
  files: FileWithId[];
  index: number
}

const FileRow: React.FC<FileRowProps> = ({ fileName, setFiles, files, index }) => {
    const {buSelected, dateSelected, uploadFileFromDropzone, uploadFileFromDropzoneMultiple, ingestionData, monthYearSelected} = useDataGouv()
    const [selectedFileType, setSelectedFileType] = useState<string | null>(null);
    const [selectedVersion, setSelectedVersion] = useState('B0')
    const [isVersioned, setIsVersioned] = useState<boolean>(false);
    
    const buOptions = buSelected && ingestionData && ingestionData![buSelected!] || [];

    const fileTypeOptions = Array.from(
      new Set(
        buOptions
          .filter((file) => file.linked === file.file_type || !file.linked || file.file_type === 'CAPEX_FORECAST' || file.linked === 'CPXFORECAST')
          .map((option) => option.file_type)
      )
    );

    const isValidTypeInFilename = (filename: string): FilenameContains | null => {
      if (filename.includes("CAPEX")) return FilenameContains.CAPEX;
      if (["CAPEX_B0", "CAPEX_R1", "CAPEX_R2", "CAPEX_R3", "CAPEX_FORECAST"].some(val => filename.includes(val))) {
        return FilenameContains.CAPEX_FORECAST;
      }
      if(fileName.includes('CPXFORECAST')) return FilenameContains.CPXFORECAST
      if (filename.includes("MAPPINGLOCAL")) return FilenameContains.MAPPING_LOCAL;
      if (filename.includes("GL")) return FilenameContains.GENERAL_LEDGER;
      if (filename.includes("SUPPLIER")) return FilenameContains.SUPPLIER_OPEX;
      if (filename.includes("CUSTOMER")) return FilenameContains.CUSTOMER_REVENUES;

      for (const value of Object.values(FilenameContains)) {
        if (filename.includes(value)) {
          return value as FilenameContains;
        }
      }

      return null;
    };

    const currentYear = monthYearSelected?.split('/')[1] ?? ' '

    const getVersionOptions = () => {
      if (buSelected === "SCA" && selectedFileType === "RECEIVABLES") {
        return ["KOS", "PNH"];
      }
      if (buSelected === "SSA" && selectedFileType === "RECEIVABLES") {
        return ["SSA", "AMA"];
      }
      if (isVersioned) {
        return ["B0", "R1", "R2", "R3"];
      }
      return [];
    };

    useEffect(() => {
      const matchedType = isValidTypeInFilename(fileName);
      if (matchedType) {
        setSelectedFileType(matchedType);
        const shouldBeVersioned =
          (matchedType === FilenameContains.BUDGET ||
          matchedType === FilenameContains.CAPEX_FORECAST ||
          matchedType === FilenameContains.MAPPING_LOCAL ||
          matchedType === FilenameContains.CPXFORECAST ||
          (buSelected === "SCA" && matchedType === "RECEIVABLES") ||
          (buSelected === "SSA" && matchedType === "RECEIVABLES")) &&
          fileTypeOptions.includes(matchedType);

          setIsVersioned(shouldBeVersioned);
      } else {
        const buOptions = ingestionData?.[buSelected!] || [];
        const fileTypeOptions = Array.from(new Set(buOptions.map((option) => option.file_type)));
  
        const regexMatchedType = fileTypeOptions.find((type) => {
          const regex = new RegExp(`(?:^|[_\\-\\.])${type}(?:[_\\-\\.]|$)`, 'i');
          return regex.test(fileName);
        });
  
        if (regexMatchedType) {
          setSelectedFileType(regexMatchedType);
          setIsVersioned(regexMatchedType === 'BUDGET' || regexMatchedType === 'CPXFORECAST' || regexMatchedType === 'CAPEX_FORECAST' || ((buSelected === "SCA" || buSelected === "SSA") && regexMatchedType === "RECEIVABLES"));
        }
      }
    }, [fileName, buSelected]);
    
    const handleFileTypeChange = (fileType: string) => {
        setSelectedFileType(fileType);
        setIsVersioned(fileType === 'BUDGET' || fileType === 'CPXFORECAST' || fileType === 'CAPEX_FORECAST' || ((buSelected === "SCA" || buSelected === "SSA") &&  fileType === "RECEIVABLES"));
    };

    const handleVersionChange = (fileVersion: string) => {
      setSelectedVersion(fileVersion);
    };

    const handleRemoveFile = () => {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
    };    

    const handleConfirmClick = () => {
      if (!buSelected || !selectedFileType) return;
    
      const linkedFiles = ingestionData?.[buSelected]?.filter(
        (fileData) => fileData.linked === selectedFileType
      );
    
      const linkedToFile = ingestionData?.[buSelected].find(
        (elem) => elem.file_type === selectedFileType
      );
    
      if (linkedFiles && linkedFiles.length > 0 && linkedToFile) {
        const filesMeta = [...linkedFiles, linkedToFile].map((f) => ({
          fileType: f.file_type,
          version: f.version,
        }));
    
        uploadFileFromDropzoneMultiple({
          bu: buSelected,
          fileAsset: selectedFileType,
          filesMeta,
          file: files[index].file,
        });
      } else {
        const fileToSend = ingestionData?.[buSelected]?.find(
          (fileData) => fileData.file_type === selectedFileType
        );
    
        if (!fileToSend) return;
    
        if (isVersioned) {
          uploadFileFromDropzone(
            buSelected,
            fileToSend.file_type,
            files[index].file,
            selectedVersion
          );
        } else {
          uploadFileFromDropzone(buSelected, fileToSend.file_type, files[index].file);
        }
      }
      handleRemoveFile();
    };        

  return (
    <tr className="border-b border-gray-200 bg-white">
      <td className="px-4 py-1.5 text-sm text-gray-800">{fileName}</td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <SelectorFileType options={ingestionData!} buName={buSelected!}   
            onChange={(e) => handleFileTypeChange(e.target.value)}
            setSelectedFileType={setSelectedFileType}
            value={selectedFileType}
        />
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800">
        {isVersioned && (
            <SelectorFileVersion
                buName={buSelected!}
                year={currentYear!}
                onChange={(e)=> handleVersionChange(e.target.value)}
                disabled={false}
                setSelectedVersion={setSelectedVersion}
                versions={getVersionOptions()}
            />
        )} 
       </td>
      {/* <td className="px-4 py-1.5 text-sm text-gray-800">John Smith</td> */}
      <td className="px-4 py-1.5 text-sm text-gray-800">
        <CustomButton text='Confirm file info' onClick={handleConfirmClick}/>
      </td>
      <td className="px-4 py-1.5 text-sm text-gray-800 cursor-pointer" onClick={handleRemoveFile}>
        <SVGIcon icon='TRASH_ICON'/>
      </td>
    </tr>
  );
};
