import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { useActiveTabs } from '../ActiveTabs';

interface JwtPayload {
  email: string;
  app: string[];
  name: string;
  exp?: number
}

type AuthContextType = {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  token: string | null;
  setIsAuthenticated: (status: boolean) => void;
  updateToken: () => void;
  verifyTokens: () => Promise<void>;
  fetchJWT: () => Promise<void>;
  loading: boolean;
  tokenInfo: JwtPayload | null
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenInfo, setTokenInfo] = useState<JwtPayload | null>(null)
  let logoutInterval: NodeJS.Timeout | null = null;

  const login = useCallback(() => {
    const isLocal = window.location.hostname === 'localhost';

    window.location.href = isLocal ? 'http://localhost:8000/auth/token' : '/auth/token';
  }, []);


  const scheduleLogout = (expiryTime: number) => {
    const expirationTimestamp = expiryTime * 1000;

    const checkLogout = () => {
      const timeLeft = expirationTimestamp - Date.now();

      console.log("Time left until logout:", timeLeft);

      if (timeLeft <= 0) {
        clearInterval(logoutInterval as NodeJS.Timeout);
        logoutInterval = null;
        logout();
      }
    };

    // Si un interval existait déjà, on le supprime
    if (logoutInterval) {
      clearInterval(logoutInterval);
    }

    // Vérification immédiate au cas où le temps serait déjà expiré
    checkLogout();

    // Ensuite, on vérifie toutes les secondes
    logoutInterval = setInterval(checkLogout, 60000);
  };

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode<JwtPayload>(token);
        const app = decoded.app
        const info: JwtPayload = {
          email: decoded.email,
          app: app,
          name: decoded.name
        }

        if (app.some(path => [
          'Dashboard/Traffic',
          'Dashboard/Finance',
          'Dashboard/Retail',
          'Dashboard/Communication',
          'Dashboard/Car Parks',
          'Dashboard/OPS',
          'Dashboard/HR'
        ].includes(path))) {
          app.push("App/Gouvernance")
        }

        if ((
          decoded.email == "greg.tordjman@ext.vinci-concessions.net" ||
          decoded.email == "thomas.pical-ext@vinci-airports.com" ||
          decoded.email == "thomas.bielle@vinci-airports.com" ||
          decoded.email == "ludovic.arnaud@vinci-concessions.com" ||
          decoded.email == "ludovic.arnaud@vinci-airports.com" ||
          decoded.email == "antoine.pinto@ext.vinci-concessions.net" ||
          decoded.email == "mohamed.khey@ext.vinci-concessions.net" ||
          decoded.email == "avner.abrami@ext.vinci-concessions.net" ||
          decoded.email == "avner.abrami@vinci-concessions.com" ||
          decoded.email == "emma.petit@vinci-concessions.com" ||
          decoded.email == "moaad.dahmane@vinci-concessions.com" ||
          decoded.email == "avner.abrami-ext@vinci-airports.com" ||
          decoded.email == "benoit.forest@vinci-airports.com")) {
          if (decoded.email != "benoit.forest@vinci-airports.com") {
            app.push("App/Streamlit")
          }
        }
        setTokenInfo(info)
        scheduleLogout(decoded.exp!)
      } catch (error) {
        console.error('Invalid token specified');
        cleanSession()
        window.location.reload();
      }
    } else {
    }
  }, [token]);

  async function fetchJWT() {
    try {
      const response = await fetch('/auth/get-jwt', {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      if (!response.ok || !data?.access_token) {
        throw new Error('Not auth');
      }
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem('comingLogin', 'login')


      setToken(data.access_token);
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
    }
  }

  const cleanSession = () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    localStorage.removeItem('access_token');
    setIsAuthenticated(false);
    setToken(null);
  }

  const logout = async () => {
    try {
      const response = await fetch('/auth/logout', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      cleanSession()
    } catch (error) {
      console.error('Error occurred during logout:', error);
    }
  }

  const checkAccessTokenValidity = async () => {
    const accessToken = localStorage.getItem('access_token');
    const response = await fetch('/auth/valid_access', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: accessToken })
    });
    const data = await response.json();
    return data.valid;
  };

  const checkRefreshTokenValidity = async () => {
    const response = await fetch('/auth/valid_refresh', {
      method: 'POST',
      credentials: "include",
    });
    const data = await response.json();
    return data.valid;
  };

  const verifyTokens = async () => {
    if (isAuthenticated && token) {
      const isRefreshTokenValid = await checkRefreshTokenValidity();
      if (!isRefreshTokenValid) {
        logout();
        return;
      }
      const isAccessTokenValid = await checkAccessTokenValidity();
      if (!isAccessTokenValid) {
        updateToken();
      }
    }
  };

  const updateToken = async () => {
    const response = await fetch('/auth/refresh', {
      method: 'POST',
      credentials: "include"
    });
    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`);
      logout()
    }
    const responseData = await response.json();
    const newAccessToken = responseData.access_token

    localStorage.setItem("access_token", newAccessToken);
    setToken(newAccessToken);
  };

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    if (storedAccessToken) {
      setToken(storedAccessToken);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token, setIsAuthenticated, updateToken, verifyTokens, fetchJWT, loading, tokenInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useAuth doit être utilisé à l’intérieur d’un AuthProvider"
    );
  }
  return context;
};